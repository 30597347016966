:root {
    --full-calendar-time-grid-slot-height: 4rem;
}

.week-day-item {
    background: var(--falcon-blue);
}

.week-day-item:hover {
    color: var(--falcon-blue)
}

.week-day-item:focus {
    background: var(--falcon-blue);
}


/** Full calendar section **/
.fc-scroller {
    overflow-y: auto !important;
}

.fc-event {
    margin-bottom: .2rem;
}

.fc .fc-h-event {
    background-color: transparent !important;
}

.fc-v-event {
    border: none !important;
    background-color: var(--falcon-gray-200) !important;
}

.fc .fc-highlight {
    background: transparent !important;
}

.fc-timegrid-slot {
    height: var(--full-calendar-time-grid-slot-height) !important;
}


.fc .fc-timegrid-axis-cushion {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.fc-theme-standard .fc-scrollgrid {
    border-color: var(--falcon-gray-200) !important;
}

.fc-scrollgrid-section-header .fc-scroller {
    overflow-y: hidden !important;
}

.fc-scrollgrid-section-header > td:first-child {
    border: none !important;
}

.fc-timegrid.fc-timeGridWeek-view a.fc-col-header-cell-cushion {
    text-transform: capitalize;
}

.fc .fc-timeGridDay-view .fc-timegrid-col, .fc .fc-timeGridWeek-view .fc-timegrid-col {
    padding: 0 !important;
}

td.fc-timegrid-slot.fc-timegrid-slot-lane {
    border-color: var(--falcon-gray-300);
}

td.fc-timegrid-slot.fc-timegrid-slot-lane.fc-timegrid-slot-minor {
    border: 1px dashed var(--falcon-gray-200) !important;
}

.fc-non-business {
    background-size: 18px 18px !important;
    background-color: rgba(237, 242, 249, .35) !important;
    border-right: 1px dashed var(--falcon-gray-300) !important;
    background-image: url("https://res.cloudinary.com/barbershop-branding/image/upload/v1632544843/Tekica%20Web/calendar-pattern_mrqu5y.svg") !important;
}

.fc-daygrid-day-frame {
    border-color: transparent !important;
}

.cutlio-navigation-bar-width-250 {
    min-width: 250px;
}

.react-datepicker__current-month {
    text-transform: capitalize;
}

.react-datepicker__day-name {
    text-transform: capitalize;
}